<template>
  <b-container fluid >
    <b-row>
      <b-col md="12">
        <iq-card class="pt-2">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
            <h5>Name:&nbsp;<span>{{appResData && appResData[0] && appResData[0].app_name ? appResData[0].app_name : ""}}</span></h5>
            <h6><small>Id:&nbsp;<span>{{appResData && appResData[0] && appResData[0].app_id ? appResData[0].app_id : ""}}</span>&nbsp;&nbsp;
              <b-btn class="btn button" variant="transparent" @click="openApp(appResData[0])"><i class="fa fa-eye mb-1 text-gideColor pointer" aria-hidden="true" title="View App"></i> </b-btn>
              <b-btn class="btn button" variant="transparent" @click="syncResult(appResData[0])"><i class="fa fa-refresh mr-1 mb-1 text-gideColor pointer" aria-hidden="true" title="Sync Result"></i></b-btn>
            </small></h6>
            <h6><small>App Type:&nbsp;<span>{{appResData && appResData[0] && appResData[0].app_type ? appResData[0].app_type : ""}}</span></small></h6>
            <h6><small>Category:&nbsp;<span>{{appResData && appResData[0] && appResData[0].app_category ? appResData[0].app_category : ""}}</span></small></h6>
            <h5>Total Participants:&nbsp;<span>{{ totalRows }}</span></h5>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col class="col-6 col-sm-6 col-md-4 col-lg-4 mb-2">
                <!-- Backend Search -->
                  <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText"
                /><!-- Backend Search -->
              </b-col>
              <b-col class="col-6 col-sm-6 col-md-4 col-lg-4 mb-2">
                <!--
                <VueJsonToCsv :json-data="appResultObjList" csv-title="userDocument" v-if="appResultObjList && appResultObjList.length">
                  <b-btn variant="primary" class="Download_button">Download
                  </b-btn>
                </VueJsonToCsv>
                -->

                <!-- Backend csv download -->
                <b-btn v-if="appResData && appResData[0] && appResData[0].app_name.includes('SAT-A-THON')" variant="primary" class="Download_button ml-3" @click="backendCsvDownloadSAT">SAT-A-THON Report</b-btn>
                <b-btn v-else variant="primary" class="Download_button" @click="backendCsvDownload">Download</b-btn>
                <!-- Backend csv download -->
              </b-col>
              <b-col class="w-100 col-12 col-sm-12 col-md-4 col-lg-4 mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="appResultDateWise()"></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="appResultObjList && appResultObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="appResultObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(user_name)="data">
                    <div>
                      <span>{{ data.item.user_name }}</span><br>
                      <span><small>Id: {{ data.item.user_id }}</small></span><br>
                      <span><small>(M): {{ data.item.user_mobile }}</small></span><br>
                      <span><small>Class: {{ data.item.class }}</small></span><br>
                    </div>
                    <button class="actionIcon btn mt-1 primary-color" title="View Assessment Result" @click="goToResultsPage(data.item.user_id)"><i class="ri-file-chart-fill font-size-20 p-1"></i></button>
                    <button class="actionIcon btn mt-1 primary-color" title="Analyse Assessment" @click="analyzeResults(data.item.user_id)"><i class="ri-search-eye-line font-size-20 p-1"></i></button>
                    <button class="actionIcon btn mt-1 primary-color" title="Fix Duplicate Results" @click="fixDuplicateResults(data.item.user_id)"><i class="fa fa-wrench font-size-20 p-1"></i></button>
                  </template>
                  <template v-slot:cell(aprs_score)="data">
                    <span>{{ data.item.aprs_score ? data.item.aprs_score : "0" }} / {{data.item.aprs_total_score}}</span>
                  </template>
                  <template v-slot:cell(aprs_percentage)="data">
                    <span> {{data.item.aprs_percentage}}%</span>
                  </template>
                  <template v-slot:cell(org_name)="data">
                    <span>{{ data.item.org_name }} {{ data.item.org_city ? "," + data.item.org_city : data.item.org_city }}</span><br>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    <span>{{ data.item.created_on | dateFormatDDMMYYYY }}</span>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { AppResults } from "../../../FackApi/api/appResults.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import moment from "moment"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "AppResultList",
  data () {
    return {
      apiName: "apps_results_console_list",
      cvCardTitle: "App Results - Quiz, Competition, Games",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "App Result List Response",
      showModelAppResultAdd: false,
      showModelAppResultEdit: false,
      showModelAppResultDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "User Details", key: "user_name", class: "text-left w-300px", sortable: true },
        { label: "Score", key: "aprs_score", class: "text-left align-text-top", sortable: true },
        { label: "Percentage", key: "aprs_percentage", class: "text-left align-text-top", sortable: true },
        { label: "Percentile Rank", key: "aprs_percentile", class: "text-left align-text-top", sortable: true },
        { label: "School", key: "org_name", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Quiz Date", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      appResultObjList: null,
      appResultEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      appResData: [],
      downloadCsv: false,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)), // in Last 7 Days
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        search_param: ""
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    if (this.$route.params.app_id) {
      this.app_id = this.$route.params.app_id
    }

    if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      await this.getDateFilter()
      this.appResultList()
    }
    else {
      this.$router.go(-1)
    }
  },
  methods: {
    /**
     * openApp
     */
    openApp (app) {
      this.$router.push(`/quiz_detail/${app.app_id}`)
    },
    /**
     * syncResult
     */
    async syncResult (examItem) {
      const resultSyncResp = await AppResults.appResultSync(this, examItem.app_id)
      if (!resultSyncResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, resultSyncResp)
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = { ...storeDate.dateRange }
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate()))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * goToResultsPage
     */
    goToResultsPage (userId) {
      this.$router.push(`/app_result_view/${this.app_id}/${userId}/0`) // 0: dont Show, 1: Show:: info message on top of the result page
    },
    /**
     * analyzeResults
     */
    analyzeResults (userId) {
      this.$router.push(`/analyse_app_result/${this.appResData[0].app_id}/${userId}?attempt=NA`)
      this.userId = userId
    },
    /**
     * calling appRefult function on date wise
     */
    async appResultDateWise () {
      this.appResultList()
      await this.setDateFilter()
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }

      this.currentBatchNo = 1
      await this.appResultList()
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.whereFilter.downloadCsv = true
      this.whereFilter.downloadSatCsv = false
      this.appResultList(false, true)
    },
    /**
     * backendCsvDownloadSAT
     */
    async backendCsvDownloadSAT () {
      this.whereFilter.downloadCsv = false
      this.whereFilter.downloadSatCsv = true
      this.appResultList(false, true)
    },
    /**
     * appResultList
     */
    async appResultList (loadViaLoadMore = false) {
      try {
        let payload = {
          app_id: this.$route.params.app_id,
          user_id: this.userId
        }

        let appResultListResp = await AppResults.appResultConsoleList(this, payload, this.whereFilter)
        if (appResultListResp.resp_status) {
          if (this.whereFilter.downloadCsv) {
            this.whereFilter.downloadCsv = false
            window.open(appResultListResp.resp_csv_file_url)
            return
          }

          if (this.whereFilter.downloadSatCsv) {
            this.whereFilter.downloadSatCsv = false
            window.open(appResultListResp.resp_csv_file_url)
            return
          }

          this.appResultObjList = appResultListResp.resp_data.data
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.appResData = [ ...appResultListResp.resp_data.data ]
          }
          else {
            this.appResData = [ ...appResultListResp.resp_data.data ]
          }

          // this.appResData = this.appResultObjList
          this.totalRows = appResultListResp.resp_data.count
        }
        else {
          this.toastMsg = appResultListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }

        this.whereFilter.downloadCsv = false
        this.whereFilter.downloadSatCsv = false
      }
      catch (err) {
        console.log("Exception occurred at appResultList() and Exception:", err.message)
      }
    },
    /**
     * fixDuplicateResults
     */
    fixDuplicateResults (userId) {
      try {
        this.$router.push(`/appResult_list_fix/${this.appResData[0].app_id}/${userId}`)
      }
      catch (err) {
        console.error("Exception in fixDuplicateResults and err: ", err)
      }
    }
  }
}
</script>
